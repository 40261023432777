/* eslint-disable react/no-danger -- when updating this component, consider replacing dangerous jsx properties */
import type { ReactNode } from 'react';
import React, { PureComponent, Fragment } from 'react';

import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import { evalLegacyConnectInlineScripts } from '@confluence/connect-utils';
import { LegacyUserElementsHover } from '@confluence/profile';
import { requireSuperBatch, realForceRequireLegacyWRM } from '@confluence/wrm';
import { ScrollToHashLinkHandler } from '@confluence/scroll';
import { RendererExtensionContext } from '@confluence/content-renderer-extension-context';
import { getApolloClient, markErrorAsHandled } from '@confluence/graphql';
import { fg } from '@confluence/feature-gating';
import { getMonitoringClient } from '@confluence/monitoring';

import { loadJSDependencies, isRequiredModuleLoaded } from './tinyMCE/loadJSDependencies';
import {
	getElementFromHash,
	isElementInViewport,
	followHashAnchorIfNeeded,
} from './tinyMCE/handle-anchors';
import { MacrosByIdsQuery } from './tinyMCE/MacrosByIdsQuery.graphql';
import type {
	MacrosByIdsQuery as MacrosByIdsQueryType,
	MacrosByIdsQueryVariables,
} from './tinyMCE/__types__/MacrosByIdsQuery';

const TIMEOUT_TO_STOP_CHECKING_FOR_MUTATIONS_AND_INTERACTION = 20000;
const userInteractionEvents = ['mousedown', 'keydown', 'wheel', 'touchstart'];

type TinyMCEClientRendererProps = {
	body: string;
	jsDependencies: string;
	cssDependencies: string;

	getLegacyContentExtensions?: () => any;
	renderWhenReady?: () => ReactNode;
	isAnonymous: boolean;
	scrollTo: string;
	cloudId: string;
	contentId: string;
};

type TinyMCEClientRendererState = {
	contentNode: string;
};

export class TinyMCEClientRendererComponent extends PureComponent<
	TinyMCEClientRendererProps,
	TinyMCEClientRendererState
> {
	private isLCEReady: Promise<void>;
	private setLCEReady: () => void;
	private MAX_CONNECT_STATIC_QUERY_MACRO_BATCH_SIZE: number;

	constructor(props) {
		super(props);

		this.state = { contentNode: '' };

		// NOTE: this construction is needed to ensure that we only bootstrap legacy
		// content extensions only when corresponding callback is available. Depending
		// on timing it may be available later than the content, so we don't want to
		// delay the content rendering and only let the initialization to wait until
		// `getLegacyContentExtensions` callback is available.
		this.setLCEReady = () => {};
		this.isLCEReady = new Promise((resolve) => {
			this.setLCEReady = resolve;
		});
		this.MAX_CONNECT_STATIC_QUERY_MACRO_BATCH_SIZE = 8;
	}

	componentDidMount() {
		this._isMounted = true;

		const { jsDependencies } = this.props;

		void this._bootstrapRichContent(jsDependencies);
		this._setBodyContainer(this._bodyContainer.current);
		void realForceRequireLegacyWRM(
			['com.atlassian.confluence.plugins.confluence-macro-browser:macro-browser-css'],
			() => {},
			() => {},
		);
	}

	componentDidUpdate(prevProps) {
		const { body, jsDependencies, getLegacyContentExtensions } = this.props;

		if (body !== prevProps.body) {
			void this._bootstrapRichContent(jsDependencies);
		}

		if (!prevProps.getLegacyContentExtensions && getLegacyContentExtensions) {
			this.setLCEReady();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
		this._stopCheckingForMutationsAndInteraction();
	}

	private _isMounted: boolean = false;
	private mutationObserver: MutationObserver | null = null;

	scrollDebounceTimerHandle = 0;
	userInteractionEventsRegistered = false;
	mutationEndTimer = 0;

	// DOM node that has Page Content
	_bodyContainer = React.createRef<any>();

	// DOM node where JS scripts are appended.
	_scriptContainer = React.createRef<any>();

	_setBodyContainer(bodyContainer) {
		this.setState({ contentNode: bodyContainer });
	}

	async _bootstrapRichContent(jsDependencies) {
		await requireSuperBatch();
		if (this._scriptContainer.current) {
			await loadJSDependencies(
				[
					'wr!com.atlassian.confluence.plugins.confluence-jira-content:confluence-jira-content-loader',
					'wr!com.atlassian.confluence.plugins.confluence-view-file-macro:view-file-macro-resources',
					'wr!confluence.web.resources:view-content',
					// CONFSIM-1578 This is required in order for Page Properties Report Macro with checkboxes to work
					'wr!com.atlassian.confluence.plugins.confluence-inline-tasks:inline-tasks-styles',
					// CONFSIM-1697 Required for sticky table header to work
					'wr!com.atlassian.confluence.plugins.sticky-table-headers:stickytableheaders-resources',
				],
				// When macro SSR is turned on the content specific scripts were loaded in SSR result in TinyMCEServerFullRenderer.tsx
				// They were put in content div so that when transition they can be removed then reload again.
				window['__SSR_RENDERED__'] ? '' : jsDependencies,
				this._scriptContainer.current,
			);

			// There is a chance that the component has already gone by the time
			// everything is loaded. In this case we don't do anything.
			if (this._isMounted && this._bodyContainer.current) {
				await evalLegacyConnectInlineScripts(this._bodyContainer.current);
				this._onContentLoaded();
			}
		}
	}

	_initializeCodeMacroScript = () => {
		try {
			if (this._bodyContainer.current?.querySelector('.code.conf-macro')) {
				if (isRequiredModuleLoaded('confluence/code-macro/async-loader')) {
					const AsyncLoader = window.require('confluence/code-macro/async-loader');
					AsyncLoader?.();
				}

				if (isRequiredModuleLoaded('confluence/code-macro/collapse-source')) {
					const CollapseSource = window.require('confluence/code-macro/collapse-source');
					CollapseSource?.init();
				}
			}
		} catch (e) {
			if (process.env.NODE_ENV !== 'production') {
				// eslint-disable-next-line no-console
				console.warn('An error occurred initializing a code macro.', e);
			}
		}
	};

	_onContentLoaded = () => {
		void this._initializeTables();
		void this._initializeConnectStaticMacros();
		this._initializeCalendarIfNeeded();
		this._initializeCodeMacroScript();
		this._setupDeeplinkChecking();
	};

	/**
	 * HOT-84603
	 * ---------
	 * Current implementation of the Team Calendars Plugin does not account for
	 * a SPA transition. It only loads the calendars once and forgets about
	 * re-initializing them once the page is unmounted. For this, we need to
	 * invoke the assets when necessary and initialize the calendars.
	 * @private
	 */
	_initializeCalendarIfNeeded() {
		const contentHasTeamCalendars =
			this._bodyContainer.current &&
			this._bodyContainer.current.querySelector('.calendar-container');
		if (contentHasTeamCalendars) {
			if (isRequiredModuleLoaded('tc/init-resources')) {
				try {
					window.require('tc/init-resources').requireResources();
				} catch (e) {
					if (process.env.NODE_ENV !== 'production') {
						// eslint-disable-next-line no-console
						console.warn('An error occurred initializing a team calendar.', e);
					}
				}
			}
		}
	}

	async _initializeTables() {
		if (!this._bodyContainer.current) {
			return;
		}

		// Only fetch assets required for table sorting if there's actually a table in the content.
		const tables = this._bodyContainer.current.querySelectorAll('table');
		if (tables.length === 0) {
			return;
		}

		if (this.props.getLegacyContentExtensions) {
			this.setLCEReady();
		}

		await this.isLCEReady;

		if (this.props.getLegacyContentExtensions) {
			const { loadSortableTable } = this.props.getLegacyContentExtensions();
			const sortableTable = await loadSortableTable();
			tables.forEach((table: any) => sortableTable.default.init(table));
		}
	}

	async _processBatchOfConnectStaticMacros(batch: Element[]) {
		const macroIds = batch.map((macro) => macro.getAttribute('data-macro-id') ?? '');
		try {
			// Fetch the views for the given batch of macroIds
			const { data } = await getApolloClient().query<
				MacrosByIdsQueryType,
				MacrosByIdsQueryVariables
			>({
				query: MacrosByIdsQuery,
				variables: {
					cloudId: this.props.cloudId,
					contentId: this.props.contentId,
					macroIds,
				},
			});

			const macros = data?.['confluence_macrosByIds'];

			batch.forEach((macroElement) => {
				const macroId = macroElement.getAttribute('data-macro-id');
				const macro = macros?.find((m) => m?.macroId === macroId);

				if (macro?.renderedMacro?.value) {
					// Replace placeholder with actual view
					macroElement.innerHTML = macro.renderedMacro.value;
				}
			});
		} catch (error) {
			markErrorAsHandled(error);
			getMonitoringClient().submitError(error, {
				attribution: Attribution.PAGE_EXPERIENCES,
			});
		}
	}

	async _initializeConnectStaticMacros() {
		if (!this._bodyContainer.current || !fg('connect_static_macro_lazy_loading_tinymce_view')) {
			return;
		}

		// Get all static connect macros.
		const connectStaticMacros: Element[] = Array.from(
			this._bodyContainer.current.querySelectorAll('.static-connect-macro-placeholder'),
		);

		// If none present on page, stop here.
		if (connectStaticMacros.length === 0) {
			return;
		}

		// In batches of MAX_CONNECT_STATIC_QUERY_MACRO_BATCH_SIZE, replace
		// the connect static macros placeholders with its actual rendered content.
		for (
			let i = 0;
			i < connectStaticMacros.length;
			i += this.MAX_CONNECT_STATIC_QUERY_MACRO_BATCH_SIZE
		) {
			const batch = connectStaticMacros.slice(
				i,
				i + this.MAX_CONNECT_STATIC_QUERY_MACRO_BATCH_SIZE,
			);
			await this._processBatchOfConnectStaticMacros(batch);
		}
	}

	/**
	 * "Deep linking" is a link not just to a page, but to a place (anchor)
	 * within that page.
	 *
	 * DeeplinkChecking waits for changes to the DOM after page load to settle
	 * before attempting to scroll to the targeted anchor.
	 *
	 * See CFE-3237 for more details
	 * https://stash.atlassian.com/projects/CONFCLOUD/repos/confluence-frontend/pull-requests/6835/overview
	 *
	 * @private
	 */
	_setupDeeplinkChecking() {
		const { scrollTo } = this.props;

		// Set-up deep link scrolling mechanism if there's a deep link requested
		if (scrollTo && scrollTo.indexOf('#') === 0 && this._bodyContainer.current) {
			const actualElementFromDOM = getElementFromHash(scrollTo);

			if (actualElementFromDOM) {
				this._setupMutationObserver(this._bodyContainer.current, actualElementFromDOM);
			}
		}
	}

	_stopCheckingForMutationsAndInteraction() {
		if (this.mutationObserver) {
			this.mutationObserver.disconnect();
			this.mutationObserver = null;
		}
		if (this.userInteractionEventsRegistered) {
			userInteractionEvents.forEach((name) => {
				window.removeEventListener(name, this._userInteracted, true);
			});
			this.userInteractionEventsRegistered = false;
		}
		if (this.mutationEndTimer !== 0) {
			clearTimeout(this.mutationEndTimer);
		}
		if (this.scrollDebounceTimerHandle !== 0) {
			clearTimeout(this.scrollDebounceTimerHandle);
		}
	}

	_userInteracted = () => {
		if (this.mutationObserver) {
			this.mutationObserver.disconnect();
			this.mutationObserver = null;
		}

		userInteractionEvents.forEach((name) => {
			window.removeEventListener(name, this._userInteracted, true);
		});
		this.userInteractionEventsRegistered = false;
	};

	/**
	 * Watch the content div for changes to it's children
	 * Whenever there is a change, check if we should scroll
	 * @param divRef: a reference to the div we're watching for change
	 * @param scrollTarget: the deep link reference element we're trying to scroll to
	 * @private
	 */
	_setupMutationObserver = (divRef, scrollTarget) => {
		// Callback function to execute when mutations are observed
		const mutationCallback = () => {
			// Every time there's a change, restart the timer that ends watching for mutations.
			clearTimeout(this.mutationEndTimer);
			this.mutationEndTimer = setTimeout(() => {
				if (this.mutationObserver) {
					this.mutationObserver.disconnect();
				}
			}, TIMEOUT_TO_STOP_CHECKING_FOR_MUTATIONS_AND_INTERACTION) as unknown as number;

			if (!isElementInViewport(scrollTarget)) {
				followHashAnchorIfNeeded(scrollTarget, this.scrollDebounceTimerHandle);
			}
		};

		// Create an observer instance linked to the callback function
		this.mutationObserver = new MutationObserver(mutationCallback);

		// Start observing the target node for configured mutations
		this.mutationObserver.observe(divRef, {
			childList: true,
			subtree: true,
		});

		// Do an initial scroll
		if (!isElementInViewport(scrollTarget)) {
			followHashAnchorIfNeeded(scrollTarget, this.scrollDebounceTimerHandle);
		}

		// Listen for user interaction to end checking for changes
		if (!this.userInteractionEventsRegistered) {
			userInteractionEvents.forEach((name) => {
				window.addEventListener(name, this._userInteracted, true);
			});
			this.userInteractionEventsRegistered = true;
		}
	};

	render() {
		const { body, cssDependencies, renderWhenReady } = this.props;
		const { contentNode } = this.state;

		return (
			<div
				id="content"
				data-testid="TinyMCERendererTestId"
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="page view legacy-renderer"
			>
				<div
					data-testid="css"
					key="css-container"
					dangerouslySetInnerHTML={{ __html: `${cssDependencies}` }}
				/>
				<div
					dangerouslySetInnerHTML={{
						__html: body,
					}}
					ref={this._bodyContainer}
					id="main-content"
					data-inline-comments-target="true"
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className="wiki-content"
				/>
				<ScrollToHashLinkHandler anchorContainer={this._bodyContainer} />
				<div key="js-container" data-testid="js" ref={this._scriptContainer} />
				{/* put conditional ones to the end to avoid changing components order */}
				{contentNode && <LegacyUserElementsHover key="legacy-use-hover" node={contentNode} />}
				{renderWhenReady && (
					<Fragment>
						{renderWhenReady()}
						<RendererExtensionContext.Consumer>
							{({ EditorLoaderLoader }) => <EditorLoaderLoader />}
						</RendererExtensionContext.Consumer>
					</Fragment>
				)}
			</div>
		);
	}
}

export const TinyMCEClientRenderer = withErrorBoundary({
	attribution: Attribution.BACKBONE,
	attributes: { fabric: false },
})(TinyMCEClientRendererComponent);
